import moment from "moment"
import { Fragment, useContext } from "react"
import React, { useState } from "react"
import { Col, Row, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import cleanLogo from "../../Assets/Images/Login/logo.png"
import { AttachFile } from "@mui/icons-material"
import Navbar from "../../Components/Navbar/Navbar"
import {
  getTodayDateISO,
  formatAmount,
  TokenExpiry,
} from "../../Helpers/Utils/Common"
import { getProject } from "../../Helpers/apiCalls/projectApi"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"

export default function PrintFranchiseInvoice(edit) {
  const { id } = useParams()
  let navigate = useNavigate()
  const today = getTodayDateISO()
  const { businessUnitLogo } = useContext(Context)

  const [inactive, setInactive] = useState(true)
  const [project, setProject] = useState([])
  const [logo, setLogo] = useState(null)

  async function fetchData() {
    setProject({})
    const response = await UseGetRequest(`projects/search`, {
      project_id: id,
    })

    if (response.error) {
      TokenExpiry(response)
    } else {
      var details = response.data.data[0]
      let tempOneTimeTotal = 0
      details?.one_time_fee?.forEach((row) => {
        tempOneTimeTotal += parseFloat(row.amount)
      })
      details.subtotal =
        parseFloat(tempOneTimeTotal) + parseFloat(details.recurring_cost_total)
      setProject(details)
    }
  }

  async function fetchBusinessInfo() {
    const response = await UseGetRequest("distributors/get_all_distributor", {
      project_id: id,
    })
    if (response.data) {
      const businessInfo = response.data.data[0]
      if (
        businessInfo?.distributor_attachment &&
        businessInfo?.distributor_attachment[0].base_64
      ) {
        setLogo(businessInfo?.distributor_attachment[0].base_64)
      } else {
        setLogo(null)
      }
    } else {
      setLogo(null)
    }
  }

  React.useEffect(() => {
    fetchData()
    fetchBusinessInfo()
  }, [])

  const viewFileInNewTab = (base64String) => {
    const base64Parts = base64String.split(",")
    const mimeType = base64Parts[0].match(/:(.*?);/)[1]
    const byteCharacters = atob(base64Parts[1])

    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: mimeType })

    const objectURL = URL.createObjectURL(blob)
    window.open(objectURL)
  }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT EXPENSES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="print-container px-3 py-2" id="printablediv">
          <div className="print-grand-total my-3 text-end print-header d-flex flex-column">
            <span>PROJECT NO. {project.id}</span>
            <span className="text-uppercase">
              {moment(project.added_on).format("MM-DD-YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-center py-1">
            <Col xs={1}>
              <img
                src={businessUnitLogo ? businessUnitLogo : cleanLogo}
                className="print-logo w-100"
                alt="logo"
              />
            </Col>
          </div>

          {/* content */}
          <div className="print-body mt-4">
            <div className="mb-5">
              <Fragment className="mb-5">
                <Row className="mt-3 mb-2">
                  <Col xs={4}>
                    <span className="edit-label">Business Unit</span>
                    <Form.Control
                      type="text"
                      name="company"
                      className="nc-modal-custom-text"
                      value={project.distributor_name}
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <span className="edit-label">Project Name</span>
                    <Form.Control
                      type="text"
                      name="name"
                      value={project.name}
                      className="nc-modal-custom-text"
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <span className="edit-label">Contract Date</span>
                    <Form.Control
                      type="date"
                      name="project_date"
                      className="nc-modal-custom-text"
                      value={project.project_date}
                      defaultValue={today}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={4}>
                    <span className="edit-label">Start Date</span>
                    <Form.Control
                      type="date"
                      name="start_date"
                      className="nc-modal-custom-text"
                      value={project.start_date}
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <span className="edit-label">End Date (deployment)</span>
                    <Form.Control
                      type="date"
                      name="start_date"
                      className="nc-modal-custom-text"
                      value={project.end_date}
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <span className="edit-label">Customer Name</span>
                    <Form.Control
                      type="text"
                      name="customer_name"
                      className="nc-modal-custom-text"
                      value={project.customer_name}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={3}>
                    <span className="edit-label">Company</span>
                    <Form.Control
                      type="text"
                      name="company"
                      className="nc-modal-custom-text"
                      value={project.company}
                      disabled
                    />
                  </Col>
                  <Col xs={3}>
                    <span className="edit-label">Contact Person</span>
                    <Form.Control
                      type="text"
                      name="contact_person"
                      className="nc-modal-custom-text"
                      value={project.contact_person}
                      disabled
                    />
                  </Col>
                  <Col xs={6}>
                    <span className="edit-label">Address</span>
                    <Form.Control
                      className="nc-modal-custom-text"
                      name="address"
                      trigger=""
                      value={project.address}
                      disabled
                    />
                  </Col>
                </Row>

                <Row className="mt-4 mb-2">
                  <Col xs={4}>
                    <span className="edit-label">
                      Cost Estimate Number/PC Number
                    </span>
                    <Form.Control
                      type="text"
                      className="nc-modal-custom-text"
                      value={formatAmount(project.ce_number)}
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <span className="edit-label">Cost Estimate Amount</span>
                    <Form.Control
                      type="text"
                      className="nc-modal-custom-text"
                      value={formatAmount(project.ce_amount)}
                      disabled
                    />
                  </Col>
                  <Col xs={4}>
                    <span className="edit-label">Internal Budget</span>
                    <Form.Control
                      type="text"
                      name="contact_person"
                      className="nc-modal-custom-text"
                      value={formatAmount(project.internal_budget)}
                      disabled
                    />
                  </Col>
                </Row>

                <Row className="mt-4 mb-2">
                  <Col xs={12} className="">
                    <span className="edit-label my-3">File Attachment/s</span>
                    <div className="mt-3">
                      {project.project_file?.map((file, index) => (
                        <>
                          <span
                            className="pointer file-name-list"
                            key={index}
                            onClick={() => viewFileInNewTab(file.base_64)}
                          >
                            <AttachFile />
                            {file.name}
                          </span>
                          <br />
                        </>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
          <button
            className="button-secondary me-3"
            onClick={() => navigate("/projectfolder")}
          >
            Close
          </button>
          <button
            className="button-tertiary me-3"
            onClick={() => navigate("/projectfolder/edit/" + id)}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  )
}
