import React, { useState, useEffect, useRef, useContext } from "react"
import { Col, Form, Row, Tab, Tabs, Modal, Container } from "react-bootstrap"
import {
  DatePicker,
  Modal as AntModal,
  Row as AntRow,
  Select as AntSelect,
  Button,
} from "antd"
import { useNavigate } from "react-router-dom"
import {
  toastStyle,
  selectDropdownStyle,
  dateFormat,
  refreshPage,
  numberFormat,
  formatAmountInt,
  formatAmount,
  formatStringFormal,
} from "../../Helpers/Utils/Common"
import Navbar from "../../Components/Navbar/Navbar"
import ReactLoading from "react-loading"

import toast from "react-hot-toast"
import downloadIcon from "../../Assets/Images/download_icon.png"
import { getType } from "../../Helpers/Utils/Common"
import Select from "react-select"
import moment from "moment"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"
import {
  changeProjectPiStatus,
  getAllProjectInv,
} from "../../Helpers/apiCalls/projectApi"
import { CheckCircleOutlined } from "@mui/icons-material"
import HoverableTable from "../../Components/TableTemplate/HoverableTable"
import Table from "../../Components/TableTemplate/OneTable"
import ReplicateModal from "../../Components/Modals/ReplicateModal"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import PiPdfContent from "./PiPdfContent"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { Context } from "../../Helpers/Context/Context"
import CustomModal from "../../Components/Modals/CustomModal"
import TextArea from "antd/es/input/TextArea"
import * as XLSX from "xlsx"

const { RangePicker } = DatePicker

export default function ProjectPurchaseInvoice() {
  let navigate = useNavigate()
  const pdfRef = useRef()
  const [isPaying, setIsPaying] = useState(false)
  const { selectedBuId } = useContext(Context)
  const dateToday = moment().format("YYYY-MM-DD")
  const [showLoader, setShowLoader] = useState(false)
  const [isLoading, setIsLoading] = useState("")
  const [remarks, setRemarks] = useState("")
  const [modal, contextModal] = AntModal.useModal()
  const [selectedRow, setSelectedRow] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [replicatePiId, setReplicatePiId] = useState("")
  const handleSelectedIdsChange = (ids) => {
    setSelectedIds(ids)
  }
  const [saving, setSaving] = useState(false)
  const [showModal, setShowModal] = useState("")

  //REPLICATE MODAL HANDLERS
  const [replicateModal, showReplicateModal] = useState(false)
  const handleShowReplicateModal = () => showReplicateModal(true)
  const handleCloseReplicateModal = () => showReplicateModal(false)

  //VIEW MODAL HANDLERS
  const [viewModal, setViewModal] = useState(false)

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  const [inactive, setInactive] = useState(true)
  const [tableData, setTableData] = useState([])
  const [bankOptions, setBankOptions] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState("")
  const [allDistributors, setAllDistributors] = useState([])
  const [totalBalance, setTotalBalance] = useState(0)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedBank, setSelectedBank] = useState("")
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [vatInType, setVatInType] = useState("")
  const [paymentForm, setPaymentForm] = useState({
    payment_date: dateToday,
    bank_id: "",
    paid_amount: 0,
  })
  const [searchTerm, setSearchTerm] = useState("")
  const [filterConfig, setFilterConfig] = useState({
    tab: "pending",
    status: "pending",
    requisitioner: "",
    term: "",
    business_unit_id: selectedBuId,
  })

  function handleSupplierChange(e) {
    setSelectedSupplier(e.name)
    // const toFilter = { target: { name: "supplier_id", value: e.value } }
    // handleFilterChange(toFilter)
    fetchSuppliers(e)
  }

  function handleAddOption(e) {
    if (e.value === "add") {
      navigate(`/projectpurchaseinvoice/add`)
    } else {
      handleShowReplicateModal()
    }
  }

  function resetValues() {
    setSelectedRow({})
    setPaymentForm({
      payment_date: dateToday,
      payment_method: "cash",
      paid_amount: 0,
      bank_id: "",
    })
    setTotalBalance(0)
    setSelectedIds([])
    setSelectedRows([])
  }

  async function fetchSuppliers(name) {
    setSuppliers([])
    // const suppliersResponse = await getAllSuppliers()
    const suppliersResponse = await UseGetRequest(
      "suppliers/get_all_supplier",
      { name: name }
    )

    if (suppliersResponse.error) {
    } else {
      suppliersResponse.data.data.map((supplier) => {
        var info = supplier
        info.type = "mango|supplier"
        setSuppliers((prev) => [...prev, info])
      })
    }
  }

  async function searchOperationalExpense() {
    setTableData([])
    setShowLoader(true)
    const response = await getAllProjectInv(filterConfig)
    if (response.data) {
      var SE = response.data.data.map((se) => {
        var info = se
        info.request_date = dateFormat(se.request_date)
        info.due_date = dateFormat(se.due_date)
        info.created_by = `${se.added_by_name} on ${moment(se.added_on).format(
          "MMM DD, YYYY hh:mm A"
        )}`
        info.approved_by = `${se.approved_by} on ${moment(
          se.approved_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.finalized_by = `${se.finalized_by} on ${moment(
          se.finalized_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.disapproved_by = `${se.disapproved_by} on ${moment(
          se.disapproved_on
        ).format("MMM DD, YYYY hh:mm A")}`
        info.sent_by = `${se.sent_by} on ${moment(se.sent_on).format(
          "MMM DD, YYYY hh:mm A"
        )}`
        info.grand_total = numberFormat(info.grand_total)
        info.bu_head_reviewed = info.reviewed_by_bu_head ? "✅" : ""
        info.bu_director_reviewed = info.reviewed_by_bu_director ? "✅" : ""
        info.display_status = formatStringFormal(info.status)
        return info
      })
      setTableData(SE)
    }
    setShowLoader(false)
  }

  async function handleChangeStatus(status, remarks) {
    setIsLoading(status)
    const ids = selectedRows.map((row) => row.id)
    const response = await changeProjectPiStatus(ids, status, remarks)
    if (response.data) {
      toast.success(
        `Successfully changed purchase invoice status to ${status} `,
        {
          style: toastStyle(),
        }
      )
      setTimeout(() => {
        refreshPage()
      }, 500)
    } else {
      toast.error("Error updating purchase invoice", {
        style: toastStyle(),
      })
    }
    setIsLoading("")
  }

  const handleTabSelect = (tabKey) => {
    if (tabKey === "complete" || tabKey === "incomplete") {
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: "",
          order_status: tabKey,
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: "",
          date_to: "",
        }
      })
    } else {
      setSelectedIds([])
      setFilterConfig((prev) => {
        return {
          ...prev,
          tab: tabKey,
          status: tabKey,
          order_status: "",
          supplier_id: "",
          vendor_id: "",
          type: "",
          date_from: "",
          date_to: "",
        }
      })
    }
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    if (name === "supplier_id") {
      var id = value.split("|")[0]
      var type = value.split("|")[1]
      var by = value.split("|")[2]
      if (by === "supplier") {
        setFilterConfig((prev) => {
          return {
            ...prev,
            supplier_id: id,
            vendor_id: "",
            type: type,
          }
        })
      } else {
        setFilterConfig((prev) => {
          return {
            ...prev,
            supplier_id: "",
            vendor_id: id,
            type: type,
          }
        })
      }
    } else {
      setFilterConfig((prev) => {
        return {
          ...prev,
          [name]: value,
        }
      })
    }
  }

  React.useEffect(() => {
    searchOperationalExpense()
  }, [filterConfig])

  async function fetchDistributors() {
    setAllDistributors([])
    const response = await getDistributor()
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAllDistributors([{ value: "", label: "All Distributors" }, ...res])
    }
  }

  React.useEffect(() => {
    // fetchSuppliers()
    fetchDistributors()
  }, [])

  async function submitApprovePI(newStatus) {
    const response = await changeProjectPiStatus(selectedIds, newStatus)
    var text
    if (newStatus === "pending") {
      text = "Returned to Pending"
    } else {
      text = newStatus
    }

    if (response.data) {
      toast.success("Project Invoice " + text + " Successfully", {
        style: toastStyle(),
      })
      refreshPage()
    } else if (response.error) {
      toast.error("Error Changing Status for Purchase Invoices", {
        style: toastStyle(),
      })
      setTimeout(() => refreshPage(), 1000)
    }
  }

  async function handleSendInvoice() {
    if (pdfRef.current) {
      setSaving(true)
      const element = pdfRef.current
      html2canvas(element, { scale: 1.5 }).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7)
        const imgWidth = 210
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const pdf = new jsPDF("", "mm", "a4")
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
        const pdfBlob = pdf.output("blob")
        const pdfFile = new File(
          [pdfBlob],
          `Purchase_Invoice_${selectedRow.id}.pdf`,
          { type: "application/pdf" }
        )
        // saveAs(pdfFile, `Purchase_Invoice_${selectedRow.id}.pdf`)

        const payload = {
          project_invoice_id: selectedRow.id,
          attachment: pdfFile,
        }
        const formValues = Object.entries(payload).map(([key, value]) => ({
          key,
          value,
        }))
        const response = await UsePostRequest(
          "project_invoices/send_to_client",
          formValues,
          true
        )
        if (response.data) {
          toast.success(response.data.response, { style: toastStyle() })
          setViewModal(false)
          setFilterConfig((prev) => ({ ...prev, status: "sent", tab: "sent" }))
          setSaving(false)
        } else {
          setSaving(false)
          toast.error("Error sending invoice", { style: toastStyle() })
        }
      })
    }
  }

  async function paymentApi(payload) {
    const response = await UsePostRequest(
      "project_invoice_payments/create",
      payload
    )
    if (response.data) {
      toast.success("Successfully created purchase invoice payment", {
        style: toastStyle(),
      })
      setTimeout(() => {
        resetValues()
        setOpenPaymentModal(false)
        setFilterConfig((prev) => ({
          ...prev,
          status: "open_bill",
          tab: "open_bill",
        }))
      }, 1000)
    } else {
      toast.error("Error creating purchase invoice payment", {
        style: toastStyle(),
      })
    }
    setIsPaying(false)
  }

  async function createMultiplePaymets() {
    // var payload
    // const response = await UsePostRequest(`project_invoice_payments/create_multiple`, payload)
  }

  async function handleSavePayment() {
    setIsPaying(true)
    if (selectedRows.length > 0) {
      createMultiplePaymets()
      // selectedRows.forEach((row) => {
      //   var payloadValues = {
      //     project_invoice_id: row.id,
      //     paid_amount: formatAmountInt(row.grand_total),
      //     bank_id: paymentForm.bank_id,
      //     payment_date: paymentForm.payment_date,
      //   }
      //   paymentApi(payloadValues)
      // })
    } else {
      const payload = {
        ...paymentForm,
        project_invoice_id: selectedRow.id,
      }
      paymentApi(payload)
    }
  }

  const confirmStatusChange = (status) => {
    modal.confirm({
      title: `Are you sure you want to mark this purchase invoices as ${status}?`,
      icon: <CheckCircleOutlined />,
      okText: "Proceed",
      cancelText: "Cancel",
      onOk: () => submitApprovePI(status),
    })
  }

  useEffect(() => {
    async function fetchBankOptions() {
      const response = await UseGetRequest("banks/search")

      if (response.data) {
        let res
        if (vatInType === "goods") {
          res = response.data.data
            .filter(
              (bank) =>
                bank.invoice_type !== "service" && bank.invoice_type !== "sales"
            )
            .map((bank) => {
              return {
                value: bank.id,
                label: bank.subtype,
              }
            })
        } else if (vatInType === "service") {
          res = response.data.data
            .filter(
              (bank) =>
                bank.invoice_type !== "goods" && bank.invoice_type !== "sales"
            )
            .map((bank) => {
              return {
                value: bank.id,
                label: bank.subtype,
              }
            })
        } else {
          res = response.data.data.map((bank) => {
            return {
              value: bank.id,
              label: bank.subtype,
            }
          })
        }

        setBankOptions(res)
      } else {
        setBankOptions([])
      }
    }
    fetchBankOptions()
  }, [vatInType])

  useEffect(() => {
    setSupplierList(
      suppliers.map((supplier) => {
        return {
          label: supplier.trade_name,
          value: supplier.id + "|" + supplier.type,
        }
      })
    )
    setSupplierList((branches) => {
      var newBranches = [...branches]
      newBranches.push({ label: "All Suppliers", value: "" })
      return newBranches.reverse()
    })
  }, [suppliers])

  function tableActionFooter(status) {
    return (
      <div>
        <Row className="w-full d-flex justify-content-end my-3">
          {selectedIds.length !== 0 && (
            <Col lg={4} className="d-flex gap-3 justify-content-end">
              {(status === "pending" || status === "reviewed") &&
              (getType() === "business_unit_finance_manager" ||
                getType() === "admin" ||
                getType() === "business_unit_finance_head") ? (
                <button
                  className={`button-warning-fill px-3 ${
                    status === "approved" ? "px-5" : ""
                  }`}
                  onClick={() => confirmStatusChange("void")}
                >
                  Void
                </button>
              ) : null}

              {status === "pending" &&
              (getType() === "business_unit_head" ||
                getType() === "business_unit_director" ||
                getType() === "admin") ? (
                <button
                  className={`button-primary px-3 ${
                    status === "approved" ? "px-5" : ""
                  }`}
                  onClick={() => confirmStatusChange("reviewed")}
                >
                  Mark as Reviewed
                </button>
              ) : null}
              {status === "reviewed" &&
              (getType() === "business_unit_finance_head" ||
                getType() === "business_unit_finance_manager" ||
                getType() === "admin") ? (
                <button
                  className={`button-warning px-3 ${
                    status === "approved" ? "px-5" : ""
                  }`}
                  onClick={() => confirmStatusChange("disapproved")}
                >
                  Disapprove
                </button>
              ) : null}
              {status === "reviewed" &&
              (getType() === "business_unit_finance_head" ||
                getType() === "business_unit_finance_manager" ||
                getType() === "admin") ? (
                <button
                  className={`button-primary px-3 ${
                    status === "approved" ? "px-5" : ""
                  }`}
                  onClick={() => confirmStatusChange("approved")}
                >
                  Approve
                </button>
              ) : null}

              {status === "approved" && getType() === "admin" ? (
                <button
                  className={`button-warning px-3 ${
                    status === "approved" ? "" : ""
                  }`}
                  onClick={() => confirmStatusChange("rejected")}
                >
                  Reject
                </button>
              ) : null}
              {status === "approved" && getType() === "admin" ? (
                <button
                  className={`button-primary px-3 ${
                    status === "approved" ? "" : ""
                  }`}
                  onClick={() => confirmStatusChange("finalized")}
                >
                  Finalize
                </button>
              ) : null}

              {/* {status === "reviewed" || status === "approved" ? (
                <button
                  className={`button-warning px-3 ${
                    status === "approved" ? "px-5" : ""
                  }`}
                  onClick={() =>
                    confirmStatusChange(
                      status === "reviewed"
                        ? "disapproved"
                        : status === "approved"
                        ? "rejected"
                        : ""
                    )
                  }
                >
                  {status === "reviewed"
                    ? "Disapprove"
                    : status === "approved"
                    ? "Reject"
                    : ""}
                </button>
              ) : null}

              <button
                className="button-fourth"
                onClick={() =>
                  confirmStatusChange(
                    status === "reviewed"
                      ? "approved"
                      : status === "approved"
                      ? "finalized"
                      : "reviewed"
                  )
                }
                disabled={
                  status === "pending" &&
                  getType() !== "admin" &&
                  getType() !== "business_unit_head" &&
                  getType() !== "business_unit_director"
                }
              >
                {status === "pending"
                  ? "Mark As Reviewed"
                  : status === "reviewed"
                  ? "Approve"
                  : status === "approved"
                  ? "Finalize"
                  : null}
              </button> */}
            </Col>
          )}
        </Row>
      </div>
    )
  }

  function commonTabContent(passedTab) {
    return (
      <div className="my-2 px-4 PO-filters d-flex">
        <Col xs={3} className="pe-3">
          <Select
            className="dropsearch-filter px-0 py-0 w-full"
            classNamePrefix="react-select"
            placeholder="Select Supplier"
            styles={selectDropdownStyle}
            value={selectedSupplier}
            options={supplierList}
            // onChange={handleSupplierChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchSuppliers(e.target.value)
              }
            }}
          />
        </Col>
        <Col xs={3}>
          <RangePicker
            className="nc-modal-custom-text w-100"
            onChange={(e) => {
              if (e) {
                setFilterConfig((prev) => ({
                  ...prev,
                  date_from: e[0].format("YYYY-MM-DD"),
                  date_to: e[1].format("YYYY-MM-DD"),
                }))
              } else {
                setFilterConfig((prev) => ({
                  ...prev,
                  date_from: "",
                  date_to: "",
                }))
              }
            }}
          />
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
          {getType() !== "requester" &&
            passedTab &&
            passedTab === "sent" &&
            selectedRows.length > 0 && (
              <button
                className="button-primary"
                onClick={() => {
                  //set vat in type to all
                  setVatInType("all")
                  setPaymentForm((prev) => ({
                    ...prev,
                    paid_amount: totalBalance,
                    balance: totalBalance,
                  }))
                  setOpenPaymentModal(true)
                }}
              >
                PAY ALL
              </button>
            )}
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          {getType() !== "requester" && (
            <button
              className="button-primary"
              onClick={() => exportToExcel()}
              disabled={tableData.length === 0}
            >
              <span className="me-2">
                <img width={20} height={20} src={downloadIcon} alt=""></img>
              </span>
              Download
            </button>
          )}
        </Col>
      </div>
    )
  }

  async function exportToExcel() {
    const data = tableData.map((record) => ({
      "PINV NO.": record.id,
      "REQUEST DATE": record.request_date,
      "DUE DATE": record.due_date,
      SUPPLIER: record.supplier_trade_name,
      AMOUNT: record.grand_total,
      REMARKS: record.remark,
    }))

    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Purchase Invoices")

    // Save as an Excel file
    XLSX.writeFile(workbook, "Purchase Invoices.xlsx")
  }

  function renderPdfContent() {
    if (selectedRow && selectedRow.status === "finalized") {
      return <PiPdfContent invoiceDetails={selectedRow} />
    }
  }

  useEffect(() => {
    renderPdfContent()
  }, [selectedRow])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PURCHASE INVOICE"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-3">
          <Col xs={12} md={7}>
            <h1 className="page-title">PURCHASE INVOICE </h1>
          </Col>
          <Col
            xs={12}
            md={3}
            className="d-flex gap-3 justify-content-end PO-filters"
          >
            <input
              type="search"
              placeholder="Search..."
              // onChange={(e) => handleFilterChange(e)}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setFilterConfig((prev) => ({ ...prev, term: searchTerm }))
                }
              }}
              className="search-bar"
            />
          </Col>
          <Col xs={12} md={3} lg={2}>
            <Select
              className="dropsearch-filter px-0 py-0 me-2 w-full"
              classNamePrefix="react-select"
              placeholder="Select"
              styles={selectDropdownStyle}
              onChange={handleAddOption}
              options={[
                { value: "add", label: "Add PINV" },
                { value: "replicate", label: "Replicate" },
              ]}
            />
          </Col>
        </Row>

        <Tabs
          activeKey={filterConfig.tab}
          defaultActiveKey={filterConfig.tab}
          id="SE-tabs"
          onSelect={handleTabSelect}
          className="TabStyle1"
        >
          <Tab eventKey="pending" title="pending" className="TabStyle2">
            {commonTabContent("pending")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                "BU DIRECTOR",
                "BU HEAD",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
                "bu_director_reviewed",
                "bu_head_reviewed",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              setSelectedRows={setSelectedRows}
              showBulkSelector={getType() !== "requester"}
              handleRowClick={(row) => {
                setPaymentForm((prev) => ({
                  ...prev,
                  paid_amount: formatAmountInt(row.grand_total),
                }))
                setSelectedRow(row)
                setSelectedRows([row])
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setPaymentForm((prev) => ({
                  ...prev,
                  paid_amount: formatAmountInt(row.grand_total),
                }))
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
              module="PINV"
              moduleTab="pending"
            />
            {tableActionFooter("pending")}
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="reviewed" title="reviewed" className="TabStyle2">
            {commonTabContent("reviewed")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              setSelectedRows={setSelectedRows}
              showBulkSelector={
                getType() === "business_unit_finance_head" ||
                getType() === "business_unit_finance_manager" ||
                getType() === "admin"
              }
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
            <div className="mb-2" />
            {tableActionFooter("reviewed")}
          </Tab>

          <Tab eventKey="approved" title="approved" className="TabStyle2">
            {commonTabContent("approved")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              setSelectedRows={setSelectedRows}
              showBulkSelector={getType() === "admin"}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
            {tableActionFooter("approved")}
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="finalized" title="finalized" className="TabStyle2">
            {commonTabContent("finalized")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              setSelectedRows={setSelectedRows}
              showBulkSelector={getType() === "admin"}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="rejected" title="rejected" className="TabStyle2">
            {commonTabContent("hold")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              showBulkSelector={false}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
            {tableActionFooter("hold")}
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="sent" title="sent" className="TabStyle2">
            {commonTabContent("sent")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              showBulkSelector={
                getType() === "admin" ||
                getType() === "business_unit_finance_head" ||
                getType() === "business_unit_finance_manager"
              }
              handleRowClick={(row) => {
                setVatInType(row.vat_in_type)
                const tempBalance = formatAmountInt(row.grand_total)

                setPaymentForm((prev) => ({
                  ...prev,
                  paid_amount: tempBalance,
                  balance: tempBalance,
                }))
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
              setTotalBalance={setTotalBalance}
              setSelectedRows={setSelectedRows}
              selectedBank={selectedBank}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="open_bill" title="EWT Payable" className="TabStyle2">
            {commonTabContent("paid")}
            <HoverableTable
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                setVatInType(row.vat_in_type)
                const tempBalance = formatAmountInt(row.balance)
                setPaymentForm((prev) => ({
                  ...prev,
                  paid_amount: tempBalance,
                }))
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              showBulkSelector={
                getType() === "admin" ||
                getType() === "business_unit_finance_head" ||
                getType() === "business_unit_finance_manager"
              }
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
              setTotalBalance={setTotalBalance}
            />
            <div className="mb-2" />
          </Tab>

          <Tab
            eventKey="closed_bill"
            title="closed bills"
            className="TabStyle2"
          >
            {commonTabContent("closed_bill")}
            <Table
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                const tempBalance = formatAmountInt(row.grand_total)
                setPaymentForm((prev) => ({
                  ...prev,
                  paid_amount: tempBalance,
                }))
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
              setTotalBalance={setTotalBalance}
            />
            <div className="mb-2" />
          </Tab>

          <Tab eventKey="disapproved" title="disapproved" className="TabStyle2">
            {commonTabContent("disapproved")}
            <Table
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
            <div className="mb-2" />
          </Tab>
          <Tab eventKey="void" title="voided" className="TabStyle2">
            {commonTabContent("disapproved")}
            <Table
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
            />
            <div className="mb-2" />
          </Tab>
          <Tab eventKey="" title="all" className="TabStyle2">
            {commonTabContent("all")}
            <Table
              tableHeaders={[
                "PINV NO.",
                "REQUEST DATE",
                "DUE DATE",
                "SUPPLIER",
                "AMOUNT",
                "REMARKS",
                "STATUS",
              ]}
              headerSelector={[
                "id",
                "request_date",
                "due_date",
                "supplier_trade_name",
                "grand_total",
                "remark",
                "display_status",
              ]}
              tableData={tableData}
              showLoader={showLoader}
              handleRowClick={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              handleRowHover={(row) => {
                setSelectedRow(row)
                setViewModal(true)
              }}
              selectedIds={selectedIds}
              onSelectedIdsChange={handleSelectedIdsChange}
            />
            <div className="mb-2" />
          </Tab>
        </Tabs>

        <div style={{ position: "absolute", left: "-9999px" }}>
          <div ref={pdfRef}>{renderPdfContent()}</div>
        </div>
      </div>

      <Modal
        footer={null}
        show={viewModal}
        onHide={() => setViewModal(false)}
        size="lg"
        centered
      >
        <Modal.Body className="">
          <div className="col-sm-12">
            <span className="custom-modal-body-title">
              REVIEW PURCHASE INVOICE
            </span>
          </div>
          <Container fluid className="modal-cont justify-content-center px-4">
            <Row className="nc-modal-custom-row">
              <Col>
                SUPPLIER NAME
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedRow?.supplier_trade_name}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
              <Col>
                BUSINESS UNIT
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedRow.distributor_name}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
              <Col lg={4}>
                REQUEST DATE
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedRow.request_date}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
            </Row>
            <Row className="nc-modal-custom-row">
              <Col xs={4}>
                DUE DATE
                <Form.Control
                  type="text"
                  name="name"
                  value={selectedRow.due_date}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
            </Row>
            <Row className="nc-modal-custom-row">
              <Col>
                REMARKS
                <Form.Control
                  as="textarea"
                  name="name"
                  value={selectedRow.remark}
                  className="nc-modal-custom-input"
                  disabled
                />
              </Col>
            </Row>
          </Container>

          <AntRow className="d-flex justify-content-between mt-5 px-4">
            <div>
              <button
                onClick={() => {
                  setSelectedRow({})
                  setViewModal(false)
                }}
                className="me-2 button-default"
              >
                Cancel
              </button>
              {(selectedRow.status === "pending" ||
                selectedRow.status === "reviewed") &&
                (getType() === "business_unit_finance_head" ||
                  getType() === "business_unit_finance_manager" ||
                  getType() === "admin") && (
                  <button
                    className="button-warning-fill me-2"
                    onClick={() => {
                      setShowModal("void")
                    }}
                  >
                    Void
                  </button>
                )}

              {(selectedRow.status === "pending" ||
                selectedRow.status === "approved" ||
                selectedRow.status === "reviewed") && (
                <button
                  className="button-primary me-2"
                  disabled={
                    (selectedRow.status === "pending" &&
                      selectedRow.reviewed_by_bu_director) ||
                    (selectedRow.status === "reviewed" &&
                      getType() !== "admin" &&
                      getType() !== "business_unit_finance_manager" &&
                      getType() !== "business_unit_finance_head") ||
                    (getType() !== "admin" &&
                      selectedRow.status === "approved") ||
                    filterConfig.tab === ""
                  }
                  onClick={() => {
                    if (
                      (selectedRow.status === "reviewed" ||
                        selectedRow.status === "approved") &&
                      getType() !== "requester"
                    ) {
                      navigate(
                        `/projectpurchaseinvoice/edit/${filterConfig.tab}/${selectedRow.id}`
                      )
                    } else if (
                      selectedRow.status === "pending" &&
                      !selectedRow.reviewed_by_bu_director
                    ) {
                      navigate(
                        `/projectpurchaseinvoice/edit/${filterConfig.tab}/${selectedRow.id}`
                      )
                    }
                  }}
                >
                  Edit
                </button>
              )}

              {selectedRow.status === "finalized" && (
                <Button
                  className="button-primary me-2"
                  onClick={handleSendInvoice}
                  disabled={saving || getType() !== "admin"}
                  loading={saving}
                >
                  Send
                </Button>
              )}
              {(getType() === "admin" ||
                getType() === "business_unit_finance_head" ||
                getType() === "business_unit_finance_manager") &&
                (selectedRow.status === "sent" ||
                  selectedRow.status === "open_bill") && (
                  <button
                    className="button-primary me-2"
                    onClick={() => {
                      setOpenPaymentModal(true)
                      setViewModal(false)
                    }}
                  >
                    Pay
                  </button>
                )}
            </div>
            <button
              className="button-primary me-2"
              onClick={() => {
                navigate(`/projectpurchaseinvoice/review/${selectedRow.id}`)
              }}
            >
              View
            </button>
          </AntRow>
        </Modal.Body>
      </Modal>

      {/* <DeleteModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        text="project Invoice"
        onDelete={handleDelete}
      /> */}
      <CustomModal
        title="CONFIRMATION"
        type="warning"
        size="md"
        withButtons={true}
        withHeader={true}
        show={showModal === "void"}
        onHide={() => setShowModal("")}
        onConfirm={() => handleChangeStatus("void", remarks)}
        okButtonText="Yes"
        okButtonType="danger"
        okButtonLoading={isLoading === "void"}
      >
        <span className="font-primary">
          Please provide reasons for why you would like to void this purchase
          invoice.
        </span>
        <TextArea
          type="text"
          className="nc-modal-custom-input mt-2"
          onChange={(e) => setRemarks(e.target.value)}
        />
      </CustomModal>

      <ReplicateModal
        withButtons
        size="md"
        showViewBtn={true}
        show={replicateModal}
        onHide={handleCloseReplicateModal}
        onProceed={() => {
          navigate(
            `/projectpurchaseinvoice/edit/${filterConfig.tab}/${replicatePiId}?replicate=true`
          )
        }}
        disableSave={!tableData.some((row) => row.id === replicatePiId)}
      >
        <div>
          <p className="font-primary fs-5">REPLICATE PURCHASE INVOICE</p>
          <Row className="nc-modal-custom-row">
            <Col>
              <p>
                Purchase Invoice No. <span className="color-red"> *</span>
              </p>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input"
                value={replicatePiId}
                onChange={(e) => setReplicatePiId(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </ReplicateModal>

      {/* PAYMENT MODAL */}
      <Modal
        footer={null}
        show={openPaymentModal}
        onHide={() => {
          setOpenPaymentModal(false)
          // setFilterConfig((prev) => ({ ...prev, status: "open_bill" }))
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <span className="custom-modal-body-title">ADD PAYMENT</span>
          <Container fluid className="modal-cont justify-content-center px-4">
            <Row className="mt-3">
              <Col xs={6}>
                <span className="review-form-label">
                  Payment Date<span className="color-red"> *</span>
                </span>
                <Form.Control
                  className="review-form-input"
                  type="date"
                  value={paymentForm.payment_date}
                  onChange={(e) =>
                    setPaymentForm((prev) => ({
                      ...prev,
                      payment_date: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col xs={6}>
                <span className="review-form-label">Balance</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={formatAmount(paymentForm.balance)}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={6}>
                <span className="review-form-label">
                  Bank<span className="color-red"> *</span>
                </span>
                <AntSelect
                  placeholder="Select bank"
                  className="w-100"
                  options={bankOptions}
                  value={paymentForm.bank_id}
                  dropdownStyle={{ zIndex: 10000 }}
                  onChange={(e, details) => {
                    setSelectedBank(details)
                    const balanceKeyMap = {
                      WC010: "WC010_balance",
                      WC100: "WC100_balance",
                      WC120: "WC120_balance",
                      WC158: "WC158_balance",
                      WC160: "WC160_balance",
                      WI010: "WI010_balance",
                      WI100: "WI100_balance",
                      WI158: "WI158_balance",
                      WI160: "WI160_balance",
                      WI011: "WI011_balance",
                    }

                    const matchedKey = Object.keys(balanceKeyMap).find((key) =>
                      details.label.includes(key)
                    )

                    const matchedValue = matchedKey
                      ? balanceKeyMap[matchedKey]
                      : null

                    if (matchedKey) {
                      if (selectedRows.length === 0) {
                        setPaymentForm((prev) => ({
                          ...prev,
                          paid_amount: parseFloat(
                            selectedRow[balanceKeyMap[matchedKey]]
                          ),
                        }))
                      } else {
                        //calculate the wht amount of each record that matches the selected wht option
                        const tempWhtBalance = selectedRows.reduce(
                          (total, row) => {
                            return total + (parseFloat(row[matchedValue]) || 0)
                          },
                          0
                        )
                        setTotalBalance(tempWhtBalance)
                        setPaymentForm((prev) => ({
                          ...prev,
                          paid_amount: tempWhtBalance,
                        }))
                      }
                    } else {
                      if (selectedRows.length === 0) {
                        setPaymentForm((prev) => ({
                          ...prev,
                          paid_amount: parseFloat(selectedRow.balance),
                        }))
                      } else {
                        setPaymentForm((prev) => ({
                          ...prev,
                          paid_amount: totalBalance,
                        }))
                      }
                    }
                    setPaymentForm((prev) => ({ ...prev, bank_id: e }))
                  }}
                />
              </Col>
              <Col xs={6}>
                <span className="review-form-label">
                  Paid Amount<span className="color-red"> *</span>
                </span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={formatAmount(paymentForm.paid_amount)}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <span className="review-form-label">Remarks</span>
              <Form.Control
                className="review-form-input"
                as="textarea"
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setPaymentForm((prev) => ({
                    ...prev,
                    remarks: e.target.value,
                  }))
                }
              />
            </Row>
          </Container>
          <AntRow className="d-flex justify-content-end mt-5 px-4">
            <div className="d-flex flex-row">
              <button
                onClick={() => {
                  setOpenPaymentModal(false)
                  resetValues()
                  // setFilterConfig((prev) => ({ ...prev, status: "sent" }))
                }}
                className="me-2 button-default"
              >
                Cancel
              </button>
              {isPaying ? (
                <div className="button-primary d-flex justify-content-center">
                  <ReactLoading
                    type="bubbles"
                    color="#FFFFFF"
                    height={30}
                    width={30}
                  />
                </div>
              ) : (
                <button
                  className="button-primary"
                  onClick={() => handleSavePayment()}
                  disabled={
                    paymentForm.paid_amount === "" || paymentForm.bank_id === ""
                  }
                >
                  SAVE
                </button>
              )}
            </div>
          </AntRow>
        </Modal.Body>
      </Modal>
      {contextModal}
    </div>
  )
}
