import React, { useState, useEffect, Fragment, useContext } from "react"
import { Row, Col } from "react-bootstrap"
import { Row as AntRow, Col as AntCol } from "antd"
import { useNavigate } from "react-router-dom"
import SalesImg from "../../Assets/Images/Dashboard/sales.png"
import ExpensesImg from "../../Assets/Images/Dashboard/expenses.png"
import ReceivablesImg from "../../Assets/Images/Dashboard/receivables.png"
import NetsalesImg from "../../Assets/Images/Dashboard/netsales.png"
import Navbar from "../../Components/Navbar/Navbar"
import { Context } from "../../Helpers/Context/Context"

import "./Dashboard.css"
import {
  formatDateNoTime,
  getName,
  getTime,
  getType,
  formatAmount,
  TokenExpiry,
  getCurrentMonthRange,
} from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { DatePicker } from "antd"
import dayjs from "dayjs"

export default function Dashboard3() {
  const { RangePicker } = DatePicker
  const dateRange = getCurrentMonthRange()
  const [filterConfig, setFilterConfig] = useState({
    date_from: dateRange.date_from,
    date_to: dateRange.date_to,
  })

  let navigate = useNavigate()
  const type = getType()
  const { selectedBuId } = useContext(Context)
  const [data, setData] = useState({
    sales: 0,
    expenses: 0,
    net_sales: 0,
    receivables: 0,
    pending_invoice: 0,
    pending_expense: 0,
  })

  const [inactive, setInactive] = useState(false)
  const days = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  }

  // Dashboard Data
  async function fetchData() {
    const response = await UseGetRequest("reports/get_dashboard_reports", {
      business_unit_id: selectedBuId,
      date_from: dayjs(filterConfig.date_from).format("YYYY-MM-DD"),
      date_to: dayjs(filterConfig.date_to).format("YYYY-MM-DD"),
    })

    if (response.data) {
      setData(response.data.data)
    } else if (response.error) {
      TokenExpiry(response)
    }
  }

  useEffect(() => {
    fetchData()
  }, [filterConfig])

  return (
    <div className="dashboard-wrapper justify-content-evenly">
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"DASHBOARD"} //Dashboard navbar index
        />
      </div>
      <div className={`dashboard ${inactive ? "inactive" : "active"}`}>
        <div className={"dashboard-wrapper"}>
          <Row className="d-flex justify-content-between align-items-end">
            <Col xs={"auto"}>
              <h2 className="font-medium">
                Hello, <b>{getName()}!</b>👋
              </h2>
            </Col>
            <Col xs={"auto"}>
              <h3 className="date-and-time">{`${getTime(new Date())} ${
                days[new Date().getDay()]
              } | ${formatDateNoTime(new Date())}`}</h3>
            </Col>
          </Row>

          <Fragment>
            {getType() !== "requester" && (
              <Row className="d-flex">
                <Col xs={24} className="mt-5 mb-3">
                  <RangePicker
                    defaultValue={[
                      dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                      dayjs(filterConfig.date_to, "YYYY-MM-DD"),
                    ]}
                    onChange={(value) => {
                      setFilterConfig((prev) => ({
                        ...prev,
                        date_from: dayjs(value[0], "YYYY-MM-DD"),
                        date_to: dayjs(value[1], "YYYY-MM-DD"),
                      }))
                    }}
                  />
                </Col>
                <Col>
                  <Row>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 ">
                      <div className="me-2 justify-content-center">
                        <img
                          src={SalesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div className="big-hdr">Sales</div>
                        <div className="stats">
                          PHP{formatAmount(data?.sales ?? 0)}
                        </div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 ">
                      <div className="me-2">
                        <img
                          src={ExpensesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row className="justify-content">
                        <div className="big-hdr">Expenses</div>
                        <div className="stats">
                          PHP{formatAmount(data.expenses)}
                        </div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2 ">
                      <div className="me-2">
                        <img
                          src={ReceivablesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div className="big-hdr">Receivables</div>
                        <div className="stats">
                          PHP{formatAmount(data.receivables)}
                        </div>
                      </Row>
                    </Col>
                    <Col className="box-1 d-flex justify-content-center align-items-center mx-2">
                      <div className="me-2">
                        <img
                          src={NetsalesImg}
                          className="print-img"
                          style={{ width: "60px", height: "60px" }}
                          alt=""
                        />
                      </div>
                      <Row>
                        <div className="big-hdr">Net Sales</div>
                        <div className="stats">
                          PHP
                          {formatAmount(data.net_sales ? data.net_sales : 0.0)}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row className="mt-5">
              <Col>
                <span className="font-primary">QUICK ACTIONS</span>
              </Col>
            </Row>
            <Row className={`d-flex ${getType() === "requester" && "mt-1"}`}>
              <Col className="mt-3">
                <AntRow gutter={[20, 20]}>
                  {getType() !== "finance_officer" && (
                    <AntCol className="box-1 mx-2" xs={12} md={5}>
                      <Row>
                        <div
                          className="header cursor d-flex flex-row justify-content-center align-items-center h-10 text-center"
                          onClick={(e) =>
                            navigate("/projectpurchaseinvoice/add")
                          }
                        >
                          <span>CREATE PURCHASE INVOICE</span>
                        </div>
                      </Row>
                    </AntCol>
                  )}
                  {getType() !== "requester" &&
                    getType() !== "business_unit_head" &&
                    getType() !== "business_unit_director" && (
                      <AntCol className="box-1 mx-2" xs={12} md={5}>
                        <Row>
                          <div
                            className="header cursor d-flex flex-row justify-content-center align-items-center h-10 text-center"
                            onClick={(e) =>
                              navigate("/billingsalesinvoice/add")
                            }
                          >
                            <span>CREATE SALES INVOICE</span>
                          </div>
                        </Row>
                      </AntCol>
                    )}

                  {getType() !== "finance_officer" && getType() === "admin" && (
                    <AntCol className="box-1 mx-2" xs={12} md={5}>
                      <Row>
                        <div
                          className="header cursor d-flex flex-row justify-content-center align-items-center h-10 text-center"
                          onClick={(e) => navigate("/journalentry/add")}
                        >
                          <span>CREATE JOURNAL ENTRY</span>
                        </div>
                      </Row>
                    </AntCol>
                  )}

                  {getType() !== "requester" &&
                    getType() !== "finance_officer" &&
                    getType() !== "business_unit_director" &&
                    getType() !== "business_unit_head" && (
                      <AntCol className="box-1 mx-2" xs={12} md={5}>
                        <Row>
                          <div
                            className="header cursor d-flex flex-row justify-content-center align-items-center h-10 text-center"
                            onClick={(e) => navigate("/projectfolder/add")}
                          >
                            <span>CREATE PROJECT</span>
                          </div>
                        </Row>
                      </AntCol>
                    )}
                </AntRow>
              </Col>
            </Row>
          </Fragment>
        </div>
      </div>
    </div>
  )
}
