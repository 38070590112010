import React from "react"
import { Modal, Container } from "react-bootstrap"
import ReactLoading from "react-loading"

const CustomModal = (props) => {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size={props.size} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="col-sm-12">
            {props.withHeader && (
              <div className="col-sm-12">
                <span className="custom-modal-body-title">{props.title}</span>
              </div>
            )}
            <Container fluid className="modal-cont justify-content-center">
              {props.children}
            </Container>
            {props.withButtons && (
              <div className="col-sm-12 mt-4 d-flex justify-content-end">
                <button className="button-default me-3" onClick={props.onHide}>
                  Cancel
                </button>
                {props.okButtonLoading ? (
                  <div
                    className={`${
                      props.okButtonType === "primary"
                        ? "button-primary"
                        : props.okButtonType === "danger"
                        ? "button-warning-fill"
                        : props.okButtonType === "default"
                        ? "button-default"
                        : ""
                    } d-flex justify-content-center ms-4`}
                  >
                    <ReactLoading
                      type="bubbles"
                      color="#FFFFFF"
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  <button
                    className={`ms-3 me-3 ${
                      props.okButtonType === "primary"
                        ? "button-primary"
                        : props.okButtonType === "danger"
                        ? "button-warning-fill"
                        : props.okButtonType === "default"
                        ? "button-default"
                        : ""
                    }`}
                    onClick={props.onConfirm}
                    disabled={props.disableSave}
                  >
                    {props.okButtonText}
                  </button>
                )}
              </div>
            )}
            {props.withCloseButtons && (
              <div className="col-sm-12 mt-4 d-flex justify-content-end">
                <button className="button-default mr-3" onClick={props.onHide}>
                  Close
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

CustomModal.defaultProps = {
  title: "",
  text: "",
  type: "",
  size: "xl",
  withButtons: false,
  withHeader: false,
  show: () => {},
  onHide: () => {},
  onEdit: () => {},
}

export default CustomModal
