import { Modal, Button, Form, Container } from "react-bootstrap"
import React, { useState } from "react"
// import { render } from "react-dom";
import warning from "../../Assets/Images/Modal/warning.png"

//css
import "./Modal.css"

function ReplicateModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size={props.size} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="col-sm-12">
            {props.withHeader && (
              <div className="col-sm-12">
                <span className="custom-modal-body-title">
                  {" "}
                  VIEW {props.title}{" "}
                </span>
              </div>
            )}
            <Container fluid className="modal-cont justify-content-center">
              {props.children}
            </Container>
            {props.withButtons && (
              <div className="col-sm-12 mt-4 d-flex justify-content-end">
                <button className="button-default me-3" onClick={props.onHide}>
                  Close
                </button>
                <button
                  className="button-primary ms-3 me-3"
                  onClick={props.onProceed}
                  disabled={props.disableSave}
                >
                  Replicate
                </button>
              </div>
            )}
            {props.withCloseButtons && (
              <div className="col-sm-12 mt-4 d-flex justify-content-end">
                <button className="button-default mr-3" onClick={props.onHide}>
                  Close
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

ReplicateModal.defaultProps = {
  title: "",
  type: "",
  size: "xl",
  withButtons: false,
  withHeader: false,
  show: () => {},
  onHide: () => {},
  onEdit: () => {},
}

export default ReplicateModal
