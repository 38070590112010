import React, { useContext, useState } from "react"
import { Col, Row, Form, Modal, Container } from "react-bootstrap"
import {
  DatePicker,
  Table,
  Modal as AntModal,
  Row as AntRow,
  Select,
} from "antd"
import { useNavigate } from "react-router-dom"
import Navbar from "../../Components/Navbar/Navbar"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  dateFormat,
  numberFormat,
  refreshPage,
} from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { toastStyle } from "../../Helpers/Utils/Common"
import { Context } from "../../Helpers/Context/Context"
import downloadIcon from "../../Assets/Images/download_icon.png"
import dayjs from "dayjs"

const { RangePicker } = DatePicker
export default function GeneralLedger() {
  let navigate = useNavigate()
  const [modal, contextHolder] = AntModal.useModal()
  const [inactive, setInactive] = useState(true)
  const { selectedBuId, primaryColor } = useContext(Context)
  const [exportToExcelLink, setExportToExcelLink] = useState("")
  const [filterConfig, setFilterConfig] = useState({
    business_unit_id: selectedBuId,
    date_from: dayjs().startOf("month").format("YYYY-MM-DD"),
    date_to: dayjs().endOf("month").format("YYYY-MM-DD"),
  })
  const [showLoader, setShowLoader] = useState(false)
  const [tableData, setTableData] = useState([])
  const [openReviewModal, setOpenReviewModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [lastExpenseTypes, setLastExpenseTypes] = useState([])

  function openNewTab(url) {
    // Opens a new browser tab with the specified URL
    window.open(url, "_blank")
  }

  const tableColumns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      align: "left",
      render: (text, record) => {
        var route
        if (record.inv_type === "BSINV") {
          route = `billingsalesinvoice/print/${record.inv_id}`
        } else if (record.inv_type === "PINV") {
          route = `projectpurchaseinvoice/review/${record.inv_id}`
        }

        if (text) {
          return (
            <button
              className="general-ledger-button"
              onClick={() => openNewTab(route)}
            >
              {text}
            </button>
          )
        }
      },
    },

    {
      title: "Posting Date",
      key: "date",
      dataIndex: "date",
    },
    {
      title: "Supplier Name",
      key: "supplier_name",
      dataIndex: "supplier_name",
    },
    {
      title: "Account Name",
      key: "name",
      dataIndex: "name",
      render: (text, record) => {
        const isTotal = text?.toLowerCase()?.includes("total")
        return (
          <span
            style={{
              fontWeight: isTotal ? "bold" : "normal",
              textTransform: isTotal ? "uppercase" : "none",
              textAlign: isTotal ? "right" : "left",
              display: "block",
              color: isTotal ? primaryColor : "",
            }}
          >
            {text}
          </span>
        )
      },
    },
    {
      title: "Debit",
      key: "debit_total",
      dataIndex: "debit_total",
      align: "right",
      render: (text, record) => {
        const isTotal = record.name.toLowerCase().includes("total")
        return (
          <span
            style={{
              fontWeight: isTotal ? "bold" : "normal",
              textTransform: isTotal ? "uppercase" : "none",
              color: isTotal ? primaryColor : "",
            }}
          >
            {text}
          </span>
        )
      },
    },
    {
      title: "Credit",
      key: "credit_total",
      dataIndex: "credit_total",
      align: "right",
      render: (text, record) => {
        const isTotal = record.name.toLowerCase().includes("total")
        return (
          <span
            style={{
              fontWeight: isTotal ? "bold" : "normal",
              textTransform: isTotal ? "uppercase" : "none",
              color: isTotal ? primaryColor : "",
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]

  const confirmDelete = (row) => {
    modal.confirm({
      title: `Are you sure you want to delete this general ledger?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: () => handleDelete(row.id),
      okType: "danger",
    })
  }

  const confirmPost = (row) => {
    modal.confirm({
      title: `Are you sure you want to post this journal entry?`,
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Post",
      cancelText: "Cancel",
      onOk: () => handlePost(row.id),
      okType: "primary",
    })
  }

  async function handlePost(id) {
    const response = await UsePostRequest(
      "journal_entries/change_status/post",
      {
        journal_entry_id: id,
      }
    )
    if (response.data) {
      toast.success("Successfully posted journal entry", {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 500)
    } else {
      toast.error("Error in posting journal entry", { style: toastStyle() })
    }
  }

  async function handleDelete(id) {
    const response = await UsePostRequest("general_ledgers/delete", {
      general_ledger_id: id,
    })
    if (response.data) {
      toast.success("Successfully deleted general ledger", {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 500)
    } else {
      toast.error("Error in deleting general ledger", { style: toastStyle() })
    }
  }

  async function fetchTableData() {
    setShowLoader(true)
    setTableData([])
    const response = await UseGetRequest(
      "reports/get_general_ledger",
      filterConfig
    )

    if (response.data.status === "success") {
      var allData = response.data.data.map((data) => {
        let info = data
        info.date = dateFormat(data.date)
        info.debit_total = numberFormat(data.debit)
        info.credit_total = numberFormat(data.credit)
        info.inv_id = data?.id?.split("-")[1]
        info.inv_type = data?.id?.split("-")[0]
        return info
      })

      // const totalDebit = allData.reduce(
      //   (sum, row) => sum + parseFloat(row.debit),
      //   0
      // )
      // const totalCredit = allData.reduce(
      //   (sum, row) => sum + parseFloat(row.credit),
      //   0
      // )

      // const totalRow = {
      //   id: "",
      //   name: "TOTAL",
      //   debit_total: numberFormat(totalDebit.toFixed(2)),
      //   credit_total: numberFormat(totalCredit.toFixed(2)),
      // }

      setTableData([...allData])
    }
    setShowLoader(false)
  }

  async function exportToExcel() {
    const response = await UsePostRequest(
      "reports/create_general_ledger",
      filterConfig,
      false
    )

    if (response.data) {
      setExportToExcelLink(response.data.url)
    } else {
      toast.error("No data found", {
        style: toastStyle(),
      })
    }
  }

  async function fetchLastSubtypes() {
    const response = await UseGetRequest(
      `expense_types/get_all_last_expense_type`
    )
    const data = response.data.data

    const lastTypesArray = data.flatMap((item) =>
      item.last_types.map((lastType) => ({
        label: lastType.name, // Adjust 'name' to the actual field for label
        value: lastType.id, // Adjust 'id' to the actual field for value
      }))
    )

    setLastExpenseTypes(lastTypesArray)
  }

  React.useEffect(() => {
    fetchTableData()
    exportToExcel()
  }, [filterConfig])

  React.useEffect(() => {
    fetchLastSubtypes()
  }, [])

  return (
    <div className="pb-5">
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"FINANCIAL STATEMENTS"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-1">
          <Col xs={6}>
            <h1 className="page-title"> GENERAL LEDGER </h1>
          </Col>
        </Row>
        <div className="mt-3">
          <Row className="my-2 mt-2 px-2">
            <Col className="d-flex flex-column flex-md-row gap-3">
              <RangePicker
                defaultValue={[
                  dayjs(filterConfig.date_from, "YYYY-MM-DD"),
                  dayjs(filterConfig.date_to, "YYYY-MM-DD"),
                ]}
                onChange={(e) => {
                  if (e) {
                    setFilterConfig((prev) => ({
                      ...prev,
                      date_from: e[0].format("YYYY-MM-DD"),
                      date_to: e[1].format("YYYY-MM-DD"),
                    }))
                  } else {
                    setFilterConfig((prev) => ({
                      ...prev,
                      date_from: "",
                      date_to: "",
                    }))
                  }
                }}
              />
              <Select
                showSearch
                className="w-medium"
                options={lastExpenseTypes}
                placeholder="Select expense type..."
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setFilterConfig((prev) => ({
                    ...prev,
                    expense_type_id: e,
                  }))
                }}
              />
            </Col>

            <Col xs={12} md={6} lg={2} className="mt-3 mt-lg-0">
              <div className="button-primary py-3 w-full text-center">
                <span className="me-2">
                  <img width={20} height={20} src={downloadIcon} alt=""></img>
                </span>
                <a href={exportToExcelLink} className="pointer custom-anchor">
                  Export To Excel
                </a>
              </div>
            </Col>
          </Row>
          <div className="mt-4">
            <div className="below">
              <div className="px-2">
                <Table
                  dataSource={tableData}
                  columns={tableColumns}
                  loading={showLoader}
                  pagination={false}
                />
              </div>
            </div>
            <div className="mb-2" />
          </div>
          <div className="mb-2" />
        </div>

        <Modal
          footer={null}
          show={openReviewModal}
          onHide={() => setOpenReviewModal(false)}
          size="lg"
          centered
        >
          <Modal.Body>
            <div className="col-sm-12">
              <span className="custom-modal-body-title">
                REVIEW JOURNAL ENTRY
              </span>
            </div>
            <Container fluid className="modal-cont justify-content-center px-4">
              <Row className="mt-3">
                <Col xs={4}>
                  <span className="review-form-label">Date</span>
                  <Form.Control
                    className="review-form-input"
                    type="date"
                    value={selectedRow.date ?? ""}
                    disabled
                  />
                </Col>
                <Col xs={4}>
                  <span className="review-form-label">Reference No</span>
                  <Form.Control
                    className="review-form-input"
                    type="text"
                    value={selectedRow.reference_no ?? ""}
                    disabled
                  />
                </Col>
                <Col xs={4}>
                  <span className="review-form-label">Amount</span>
                  <Form.Control
                    className="review-form-input"
                    type="text"
                    value={selectedRow.amount ?? ""}
                    disabled
                  />
                </Col>
              </Row>
            </Container>

            <AntRow className="d-flex justify-content-between mt-5 px-4">
              <button
                className="button-primary"
                onClick={() => {
                  navigate("/generalledger/view", { state: selectedRow })
                }}
              >
                View
              </button>
              <div>
                <button
                  onClick={() => {
                    setSelectedRow({})
                    setOpenReviewModal(false)
                  }}
                  className="me-2 button-default"
                >
                  Cancel
                </button>
                <button
                  className="button-warning-fill me-2"
                  onClick={() => {
                    confirmDelete(selectedRow)
                  }}
                >
                  Delete
                </button>
                <button
                  className="button-primary me-2"
                  onClick={() => {
                    setOpenReviewModal(false)
                    navigate("/generalledger/edit", { state: selectedRow })
                  }}
                >
                  Edit
                </button>
                <button
                  className="button-primary"
                  onClick={() => {
                    confirmPost(selectedRow)
                  }}
                >
                  Post
                </button>
              </div>
            </AntRow>
          </Modal.Body>
        </Modal>
      </div>
      {contextHolder}
    </div>
  )
}
