import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"

//UTILITIES
import { refreshPage, toastStyle } from "../../Helpers/Utils/Common"

// Components
import Navbar from "../../Components/Navbar/Navbar"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import DeleteModal from "../../Components/Modals/DeleteModal"
import { DeleteOutline } from "@mui/icons-material"
import Table from "../../Components/TableTemplate/DataTable"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"

const Classifications = () => {
  const [inactive, setInactive] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [showModal, setShowModal] = useState("")
  const [details, setDetails] = useState({
    name: "",
    subtypes: [
      {
        name: "",
      },
    ],
  })
  const [submittableForm, setSubmittableForm] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [tableData, setTableData] = useState([])

  function validate(formDetails) {
    const { name } = formDetails

    const isFormValid = name

    setSubmittableForm(isFormValid)
  }

  function resetFeilds() {
    setDetails({
      name: "",
      subtypes: [
        {
          name: "",
        },
      ],
    })
  }

  async function fetchTableData() {
    setShowLoader(true)
    const response = await UseGetRequest(
      "classifications/get_all_classification"
    )
    if (response.data) {
      const data = response.data.data.map((item) => {
        return {
          ...item,
          subtypes: item.classification_subtypes,
          _subtypes: (
            <ul className="m-0">
              {item.classification_subtypes
                .filter((subtype) => subtype.name)
                .map((subtype, index) => (
                  <li key={index}>{subtype.name}</li>
                ))}
            </ul>
          ),
        }
      })
      setTableData(data)
    } else {
      setTableData([])
    }
    setShowLoader(false)
  }

  async function handleDelete() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "classifications/delete",
      {
        classification_id: selectedRow.id,
      },
      false
    )

    //display any errors
    if (response.error) {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
      return
    }

    if (response) {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    } else {
      setIsClicked(false)
      toast.success(response.error.messages.message, {
        style: toastStyle(),
      })
    }
  }

  async function handleCreate() {
    setIsClicked(true)
    const payload = {
      name: details.name,
      subtypes: details.subtypes,
    }
    const response = await UsePostRequest(
      "classifications/create",
      { ...payload },
      false
    )

    if (response.error) {
      if (
        response.error.response.data.messages.status?.code &&
        response.error.response.data.messages.status?.code === 1062
      ) {
        setIsClicked(false)
        toast.error(`Classification "${details.name}" already exists.`, {
          style: toastStyle(),
        })
      } else {
        toast.error(`Something went wrong.`, {
          style: toastStyle(),
        })
      }
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }

  async function handleUpdate() {
    setIsClicked(true)
    const payload = {
      classification_id: details.id,
      name: details.name,
      subtypes: details.subtypes,
    }
    const response = await UsePostRequest(
      "classifications/update",
      {
        ...payload,
      },
      false
    )

    if (response.error) {
      if (
        response.error.response.data.messages.status?.code &&
        response.error.response.data.messages.status?.code === 1062
      ) {
        setIsClicked(false)
        toast.error(`Classification "${details.name}" already exists.`, {
          style: toastStyle(),
        })
      } else {
        toast.error(`Something went wrong.`, {
          style: toastStyle(),
        })
      }
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }

    setDetails(updatedDetails)
  }

  useEffect(() => {
    validate(details)
  }, [details])

  useEffect(() => {
    fetchTableData()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"WH CLASSIFICATIONS"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={5}>
            <h1 className="page-title m-0 w-full">CLASSIFICATIONS</h1>
          </Col>
          <Col xs={7} className="d-flex justify-content-end">
            <button className="add-btn" onClick={() => setShowModal("add")}>
              Add
            </button>
          </Col>
        </Row>
        <Row className="tab-content pt-3">
          <Col>
            <div className="below">
              <Table
                tableHeaders={["NAME", "SUB-CLASSIFICATIONS"]}
                headerSelector={["name", "_subtypes"]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setSelectedRow(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <AddModal
        title="CLASSIFICATION"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => {
          setShowModal("")
          resetFeilds()
        }}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column gap-3">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
              />
            </Col>
            <Col>
              SUB-CLASSIFICATIONS
              <div className="d-flex flex-column gap-2 pe-md-5">
                {details.subtypes &&
                  details.subtypes.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row gap-3 align-items-center"
                    >
                      <Form.Control
                        type="text"
                        value={item.name} // Accessing the 'name' property of the object
                        className="nc-modal-custom-input-edit"
                        onChange={(e) => {
                          const updatedValue = e.target.value
                          setDetails((prev) => ({
                            ...prev,
                            subtypes: prev.subtypes.map(
                              (sub, i) =>
                                i === index
                                  ? { ...sub, name: updatedValue }
                                  : sub // Updating the 'name' property
                            ),
                          }))
                        }}
                        required
                      />
                      <DeleteOutline
                        className="cursor-pointer"
                        onClick={() => {
                          setDetails((prev) => ({
                            ...prev,
                            subtypes: prev.subtypes.filter(
                              (_, i) => i !== index
                            ),
                          }))
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="me-auto">
              <button
                className="button-primary w-full"
                onClick={() => {
                  setDetails((prev) => ({
                    ...prev,
                    subtypes: [...prev.subtypes, { name: "" }], // Adding an object instead of a string
                  }))
                }}
              >
                + Add Sub-classification
              </button>
            </Col>
          </Row>
        </div>
      </AddModal>
      <EditModal
        title="CLASSIFICATION"
        size="xl"
        type="branch"
        show={showModal === "edit"}
        onHide={() => {
          setShowModal("")
          setSelectedRow({})
          resetFeilds()
        }}
        onSave={() => handleUpdate()}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column gap-3">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={details.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
              />
            </Col>
            <Col>
              SUB-CLASSIFICATIONS
              <div className="d-flex flex-column gap-2 pe-md-5">
                {details.subtypes &&
                  details.subtypes.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row gap-3 align-items-center"
                    >
                      <Form.Control
                        type="text"
                        value={item.name} // Accessing the 'name' property of the object
                        className="nc-modal-custom-input-edit"
                        onChange={(e) => {
                          const updatedValue = e.target.value
                          setDetails((prev) => ({
                            ...prev,
                            subtypes: prev.subtypes.map(
                              (sub, i) =>
                                i === index
                                  ? { ...sub, name: updatedValue }
                                  : sub // Updating the 'name' property
                            ),
                          }))
                        }}
                        required
                      />
                      <DeleteOutline
                        className="cursor-pointer"
                        onClick={() => {
                          setDetails((prev) => ({
                            ...prev,
                            subtypes: prev.subtypes.filter(
                              (_, i) => i !== index
                            ),
                          }))
                        }}
                      />
                    </div>
                  ))}
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="me-auto">
              <button
                className="button-primary w-full"
                onClick={() => {
                  setDetails((prev) => ({
                    ...prev,
                    subtypes: [...prev.subtypes, { name: "" }], // Adding an object instead of a string
                  }))
                }}
              >
                + Add Sub-classification
              </button>
            </Col>
          </Row>
        </div>
      </EditModal>
      <ViewModal
        withButtons
        can_delete={false}
        editable={true}
        size="lg"
        show={showModal === "view"}
        onHide={() => {
          setShowModal("")
          setSelectedRow({})
          resetFeilds()
        }}
        onEdit={() => {
          setDetails(selectedRow)
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                value={selectedRow.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              SUB-CLASSIFICATIONS
              {selectedRow?.subtypes?.length === 0 && (
                <p className="text-black mt-2">No Sub-classifications</p>
              )}
              <div className="d-flex flex-column gap-2 pe-md-5">
                {selectedRow.subtypes &&
                  selectedRow.subtypes.map((item, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      value={item.name}
                      className="nc-modal-custom-input-edit"
                      disabled
                    />
                  ))}
              </div>
            </Col>
          </Row>
        </div>
      </ViewModal>
      <DeleteModal
        text="classification"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
        isClicked={isClicked}
      />
    </div>
  )
}

export default Classifications
